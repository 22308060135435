// src/pages/AdminPage.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CustomerList from '../components/CustomerList';
import UpfitterList from '../components/UpfitterList';
import UserList from '../components/UserList';
import ContactList from '../components/ContactList'
import SalesIdList from '../components/SalesIdList';
import AdminTable from '../components/AdminTable';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AdminPage() {
  const query = useQuery();
  const [activeSection, setActiveSection] = useState(query.get('section') || '');
  const [searchQuery, setSearchQuery] = useState(query.get('search') || '');


  useEffect(() => {
    const section = query.get('section');
    const search = query.get('search');
    if (section) {
      setActiveSection(section);
    }
    if (search) {
      setSearchQuery(search);
    }
  }, [query]);


  return (
    <>
      {activeSection === 'customers' && (
        <div>
          <CustomerList search={searchQuery}/>
        </div>
      )}
      {activeSection === 'upfitters' && (
        <div>
          <UpfitterList />
        </div>
      )}
      {activeSection === 'contacts' && (
        <div>
          <ContactList />
        </div>
      )}
      {activeSection === 'users' && (
        <div>
          <UserList />
        </div>
      )}
      {activeSection === 'salesids' && (
        <div>
          <SalesIdList search={searchQuery} />
        </div>
      )}
      {activeSection === 'colorCodes' && (
        <div>
          <AdminTable collectionName="colorCodes" fields={['strFactory', 'strColor', 'strColorName']} />
        </div>
      )}
      {activeSection === 'eventCodes' && (
        <div>
          <AdminTable collectionName="eventCodes" fields={['strFactory', 'strManStat', 'strFactStatus', 'strFactStatusCust', 'strStatusColor']} />
        </div>
      )}
      {activeSection === 'modelCodes' && (
        <div>
          <AdminTable collectionName="modelCodes" fields={['strFactory', 'strModel', 'strModelName']} />
        </div>
      )}
      {activeSection === '' && (
        <div>
          <AdminTable collectionName="none" fields={[]} />
        </div>
      )}
    </>
  );
}

export default AdminPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Button, Modal, OverlayTrigger, Tooltip, FormControl, InputGroup, Form, Pagination, ProgressBar, Spinner, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import VehicleForm from './VehicleForm';
import { CircleFill, PlusCircle, PlusCircleFill, InfoCircle, Trash, Wrench, ArrowBarUp, Search, MicrosoftTeams, ArrowDownUp, Coin, Funnel, BoxArrowUpRight, FiletypeXlsx, CurrencyDollar, Buildings } from "react-bootstrap-icons";
import { read, utils, writeFile } from 'xlsx';
import { useFirestore } from '../contexts/FirestoreContext';
//import { createTeamsFoldersForVehicles } from '../utils/teamsUtils';
import { Timestamp } from 'firebase/firestore';
import Papa from 'papaparse';


function VehicleList({ selectedOrder, addButtonActive, allVehicles = false, rowPages = 10 }) {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState([]);
  const [vehicleToShow, setVehicleToShow] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowPages);
  const [totalRows, setTotalRows] = useState(0);
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [teamsProgress, setTeamsProgress] = useState(0);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const { getData, queryData, updateData, addData, deleteData, getDocument, onSnapshotQueryData, onSnapshotData } = useFirestore();
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [reload, setReload] = useState(0);
  const [eventCodes, setEventCodes] = useState({});
  const [modelCodes, setModelCodes] = useState({});
  const [colorCodes, setColorCodes] = useState({});
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // Default direction is 'asc'
  const [salesOrders, setSalesOrders] = useState([]); // Store Sales Orders
  const [customers, setCustomers] = useState([]); // Store Customers
  const [currentVehicleInfo, setCurrentVehicleInfo] = useState(null); // Track current vehicle info
  const [currentVehicleStatus, setCurrentVehicleStatus] = useState(''); // Track if the vehicle is New or Updated
  const [selectedVehicleStatuses, setSelectedVehicleStatuses] = useState(['Active']);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [etaStartDate, setEtaStartDate] = useState(null);
  const [etaEndDate, setEtaEndDate] = useState(null);
  const [upfitterStatusCodes, setUpfitterStatusCodes] = useState(null);
  const [upfitters, setUpfitters] = useState(null);
  const [paidSelected, setPaidSelected] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      const vehicleUnsubscribe = selectedOrder
        ? onSnapshotQueryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr, (vehicleData) => {
          setVehicles(vehicleData);
        })
        : onSnapshotData('vehicles', (vehicleData) => {
          setVehicles(vehicleData);
        });

      const salesOrderUnsubscribe = onSnapshotData('salesOrders', (salesOrderData) => {
        setSalesOrders(salesOrderData);
      });

      const customerUnsubscribe = onSnapshotData('customers', (customerData) => {
        setCustomers(customerData);
      });

      const upfittersUnsubscribe = onSnapshotData('Upfitters', (upfittersData) => {
        setUpfitters(upfittersData);
      });

      // Clean up the listeners on component unmount
      return () => {
        vehicleUnsubscribe();
        salesOrderUnsubscribe();
        customerUnsubscribe();
        upfittersUnsubscribe();
      };
    };

    fetchData();
  }, [onSnapshotData, onSnapshotQueryData, selectedOrder]);

  useEffect(() => {
    const fetchUpfitterStatusOptions = async () => {
      try {
        const upfitterStatusData = await queryData('eventCodes', 'strFactory', '==', 'UPFITTER');
        setUpfitterStatusCodes(upfitterStatusData);
      } catch (error) {
        console.error('Error fetching upfitter status options:', error);
      }
    };
    fetchUpfitterStatusOptions();
  }, []);

  useEffect(() => {
    const currentSection = getCurrentSection();
    const defaultStatus = currentSection === "salesorders" ? "All" : "Active";
    setSelectedVehicleStatuses([defaultStatus]);
  }, []);


  const handleSalesOrderClick = (soNumber) => {
    navigate(`/salesorders?search=${soNumber}`);
  }

  const handleVehicleStatusSelect = (status) => {
    setSelectedVehicleStatuses([status]); // Only one status should be selected at a time
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const calculateGreenPercentage = () => {
    const greenVehicles = filteredVehicles.filter(vehicle => {
      const statusColor = getStatusColor(vehicle);
      return statusColor === 'green'; // Bootstrap's green color
    });

    const percentage = filteredVehicles.length > 0 ? (greenVehicles.length / filteredVehicles.length) * 100 : 0;
    return Math.round(percentage); // Return the rounded percentage
  };

  const handleStartDateChange = (date) => setEtaStartDate(date);
  const handleEndDateChange = (date) => setEtaEndDate(date);

  const formatDateUTC = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // ISO-like format without time
  };

  // Regular JavaScript function to get the current section from the URL path
  const getCurrentSection = () => {
    // Get the current path
    const pathname = window.location.pathname;

    // Extract the first section after the root, i.e., the first part of the URL path
    const section = `${pathname.split('/')[1] || ''}`;

    return section;
  };

  const getStatusColor = (vehicle) => {
    // 5. Delivered to Customer (Green)
    if (vehicle.manualStatus === 'Ordered') {
      return 'white'; // Ordered status
    }
    if (vehicle.manualStatus === 'Scheduled') {
      return 'saddlebrown'; // Scheduled status
    }
    if (vehicle.manualStatus === 'Produced') {
      return 'saddlebrown'; // Produced status
    }
    if (vehicle.manualStatus === 'In Transit') {
      return 'saddlebrown'; // In Transit status
    }
    if (vehicle.manualStatus === 'On the Ground') {
      return 'orange'; // On the Ground status
    }
    if (vehicle.manualStatus === 'Customer Signed') {
      return 'yellow'; // Customer Signed status
    }
    if (vehicle.manualStatus === 'At Upfitter') {
      return 'blue'; // At Upfitter status
    }
    if (vehicle.manualStatus === 'Delivered') {
      return 'lightgreen'; // Delivered status
    }
    if (vehicle.manualStatus === 'Complete') {
      return 'green'; // Complete status
    }


    // 4. Process of Getting Vehicle Ready for Customer Delivery
    if (vehicle.dtCustSignedDate) {
      return 'blue'; // Customer Signed and Ownership Changed
    }

    if (vehicle.strUpfitterStatus === 'Complete') {
      return 'blue'; // At Upfitter (Complete)
    }

    if (vehicle.strUpfitterStatus || vehicle.dtUpfitterETA) {
      return 'blue'; // At Upfitter
    }

    // 3. Ready for Customer Signature
    if (vehicle.dtRecvDate) {
      return 'orange'; // On the Ground (Delivered at dealership or upfitter)
    }

    if (vehicle.dtPDI) {
      return 'orange'; // PDI (Pre-Delivery Inspection)
    }

    // 2. Vehicle In Process (Check for sub-statuses in reverse order)
    if (vehicle.dtManETA) {
      return 'saddlebrown'; // In Transit to Destination
    }

    if ((vehicle.strManStat >= 3800 && vehicle.strFactory === 'GM') ||
      (vehicle.strManStat === 'Produced' && vehicle.strFactory === 'Ford')) {
      return 'saddlebrown'; // Produced - 
    } //Might need to convert strManStat to number

    if (vehicle.dtProdDate) {
      return 'saddlebrown'; // Scheduled (GM TPW or Ford Build Date)
    }

    // 1. Vehicle Ordered (Default Status - No Color)
    return '#00000000'; // No color for default state
  };

  const getETAStr = (vehicle) => {
    const formatDateFromTimestamp = (timestamp) => {
      const date = timestamp.toDate();
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // UTC month
      const day = String(date.getUTCDate()).padStart(2, '0');       // UTC day
      return `${month}/${day}/${year}`;
    };

    if (vehicle.manualETA) {
      const dateStr = formatDateFromTimestamp(vehicle.manualETA);
      if (vehicle.manualStatus === 'Complete') {
        return '';
      } else {
        return `(${dateStr})`;
      }
    }
    //(On Ground, Produced, Delivered, Complete)

    if (vehicle.manualStatus === "On the Ground" || vehicle.manualStatus === "Produced" || vehicle.manualStatus === "Delivered" || vehicle.manualStatus === "Complete" ) {
      return '';
    }

    if (vehicle.manualStatus) {
      return '';
    }

    // Upfitter ETA
    if (vehicle.dtUpfitterETA) {
      const dateStr = formatDateFromTimestamp(vehicle.dtUpfitterETA);
      return `U: ${dateStr}`;
    }

    // Default to manufacturer ETA
    if (vehicle.dtRecvDate) {
      return ``;
    }

    // Manufacturer ETA
    if (vehicle.dtManETA) {
      const dateStr = formatDateFromTimestamp(vehicle.dtManETA);
      return `M: ${dateStr}`;
    }

    // Delivery ETA
    if (vehicle.dtProdDate) {
      const dateStr = formatDateFromTimestamp(vehicle.dtProdDate);
      return `P: ${dateStr}`;
    }

    return ''; // Default
  };

  const getETADate = (vehicle) => {
    if (vehicle.manualStatus && vehicle.manualStatus !== "In Transit" && vehicle.manualStatus !== "Delivered") return null
    if (vehicle.manualETA) return vehicle.manualETA.toDate();
    if (vehicle.dtUpfitterETA) return vehicle.dtUpfitterETA.toDate();
    if (vehicle.dtRecvDate) return null;
    if (vehicle.dtManETA) return vehicle.dtManETA.toDate();
    return null;
  }

  const getETADateSort = (vehicle) => {
    if (vehicle.manualETA) return vehicle.manualETA.toDate();
    if (vehicle.dtUpfitterETA) return vehicle.dtUpfitterETA.toDate();
    if (vehicle.dtRecvDate) return vehicle.dtRecvDate.toDate();
    if (vehicle.dtManETA) return vehicle.dtManETA.toDate();
    if (vehicle.dtProdDate) return vehicle.dtProdDate.toDate();
    return null;
  }

  const getCustomerName = (salesOrdNbr) => {
    const salesOrder = salesOrders.find(order => order.strSalesOrdNbr === salesOrdNbr);
    if (!salesOrder) return 'Unknown'; // Sales order not found
    const customer = customers.find(cust => cust.strCustNbr === salesOrder.strCustNbr);
    return customer ? customer.strCustName : 'Unknown'; // Return customer name or 'Unknown' if not found
  };

  const getSalesOrdPONbr = (salesOrdNbr) => {
    const salesOrder = salesOrders.find(order => order.strSalesOrdNbr === salesOrdNbr);
    if (!salesOrder) return ''; // Sales order not found
    
    return salesOrder.strPONbr ? salesOrder.strPONbr : ''; // Return customer name or 'Unknown' if not found
  };

  const getCustomer = (salesOrdNbr) => {
    const salesOrder = salesOrders.find(order => order.strSalesOrdNbr === salesOrdNbr);
    if (!salesOrder) return null; // Sales order not found
    const customer = customers.find(cust => cust.strCustNbr === salesOrder.strCustNbr);
    return customer ? customer : null; // Return customer name or 'Unknown' if not found
  };

  const getUpfitter = (strUpfitterNbr) => {
    if (strUpfitterNbr === '') {
      return null
    }
    const upfitter = upfitters.find(upfit => upfit.strUpfitterNbr === strUpfitterNbr);
    return upfitter ? upfitter : null; // Return upfitter or 'Unknown' if not found
  };

  const getDOASReq = (salesOrdNbr) => {
    const salesOrder = salesOrders.find(order => order.strSalesOrdNbr === salesOrdNbr);
    if (!salesOrder) return false; // Sales order not found
    return salesOrder.bDOASReq ? true : false; 
  };

  const handleExportToXLSX = async () => {
    if (selectedVehicles.length === 0) {
      alert("Please select at least one vehicle to export.");
      return;
    }

    const section = getCurrentSection();
    const dataToExport = await Promise.all(
      selectedVehicles.map(async (vehicle) => {
        // Use `await` for asynchronous operations
        const customer = await getCustomer(vehicle.strSalesOrdNbr) || {}; // Default to an empty object if null/undefined

        const upfitter = await getUpfitter(vehicle.strUpfitterNbr) || {};

        if (section === 'salesorders') {
          return {
            "Sales Order Number": vehicle.strSalesOrdNbr ?? "",
            "DOAS Indicator": getDOASReq(vehicle.strSalesOrdNbr) ?? "",
            "Customer Number": customer.strCustNbr ?? "",
            "Customer Name": customer.strCustName ?? "",
            "CDK Ford Number": customer.strCDKCustNbrFord ?? "",
            "CDK GM Number": customer.strCDKCustNbrGM ?? "",
            "Ford FIN": customer.strCustFordFIN ?? "",
            "GM FAN": customer.strCustGMFAN ?? "",
            "Addr 1": customer.strAddressLine1 ?? "",
            "Addr 2": customer.strAddressLine2 ?? "",
            "City ": customer.strCity ?? "",
            "State": customer.strState ?? "",
            "Zip": customer.strZip ?? "",
            "Order Number": vehicle.strOrdNbr ?? "",
            "Stock Number": vehicle.strStockNbr || vehicle.strVin?.slice(-8) || "",
            "Vin": vehicle.strVin ?? "",
            "Year": vehicle.strYR ?? "",
            "Make": vehicle.strMake ?? "",
            "Model Name": modelCodes[
              (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel : "")
            ] ||
              modelCodes[
              (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
              ] ||
              (vehicle.strModel || ""),
            "Model Code": vehicle.strModel,
            "Color": colorCodes[`${vehicle.strFactory || ''} - ${vehicle.strColor || ''}`] ?? vehicle.strColor,
            "Keys": vehicle.curKeys ?? "",
            "MSRP": vehicle.curVehMSRP ?? "",
            "Price": vehicle.curVehSalePrice ?? "",
            "Cost": vehicle.curVehCOS ?? "",
            "Warranty Sale": vehicle.curWarrSale ?? "",
            "Warranty Cost": vehicle.curWarrCost ?? "",
            "Warranty Type": vehicle.strWarrType ?? "",
            "Tag & Title Fees": vehicle.curTTFees ?? "",
            "VQ2 - Hold Back": vehicle.curVQ2 ?? "",
            "Vehicle Incentive": vehicle.curVehIncent ?? "",
            "PO Line Number": vehicle.strPONbr ?? "",
            "PO Number": getSalesOrdPONbr(vehicle.strSalesOrdNbr) ?? "",
            "DOAS Amount": vehicle.curDoas ?? "",
            "Accessories": vehicle.currAccess ?? "",
            "Upfit Cost": vehicle.curUpfitCost ?? "",
            "Transport Cost": vehicle.curTransCost ?? "",
            "Taxes": vehicle.curTaxes ?? "",
            "Rebate": vehicle.curRebate ?? "",
            "Manufacture ETA Date": formatDateUTC(vehicle.dtManETA) ?? "",
            "Production Date": formatDateUTC(vehicle.dtProdDate) ?? "",
            "Manufacture Status": vehicle.strManStat ?? "",
            "Received Date": formatDateUTC(vehicle.dtRecvDate) ?? "",
            "PDI Date": formatDateUTC(vehicle.dtPDI) ?? "",
            "Sales Date": formatDateUTC(vehicle.dtSaleDate) ?? "",
            "Upfitter Number": vehicle.strUpfitterNbr ?? "",
            "Upfitter Name": upfitter.strUpfitterName ?? "",
            "Sent To Upfitter Date": formatDateUTC(vehicle.dtSentToUpfitter) ?? "",
            "Upfitter ETA Date": formatDateUTC(vehicle.dtUpfitterETA) ?? "",
            "Upfitter Complete Date": formatDateUTC(vehicle.dtUpfitterComp) ?? "",
            "Upfitter Status": getUpfitterStatus(vehicle.strUpfitterStatus) ?? "",
            "Manual Status": vehicle.manualStatus ?? "",
            "Manual ETA Date": formatDateUTC(vehicle.manualETA) ?? "",
            "Billed CDK Indicator": vehicle.bBillCDK ?? false,
            "Recall Indicator": vehicle.bRecall ?? false,
            "Displayed Status": getDisplayedStatus(vehicle) ?? "",
            "Paid Date": formatDateUTC(vehicle.dtPaidDate) ?? "",
          };
        }
        return {
          "Sales Order Number": vehicle.strSalesOrdNbr ?? "",
          "Vehicle Ordered Date": formatDateUTC(vehicle.createdDate) ?? "",
          "Customer Number": customer.strCustNbr ?? "",
          "Customer Name": customer.strCustName ?? "",
          "Order Number": vehicle.strOrdNbr ?? "",
          "Stock Number": vehicle.strStockNbr || vehicle.strVin?.slice(-8) || "",
          "Vin": vehicle.strVin ?? "",
          "Year": vehicle.strYR ?? "",
          "Make": vehicle.strMake ?? "",
          "Model Name": modelCodes[
            (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel : "")
          ] ||
            modelCodes[
            (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
            ] ||
            (vehicle.strModel || ""),
          "Model Code": vehicle.strModel,
          "Color": colorCodes[`${vehicle.strFactory || ''} - ${vehicle.strColor || ''}`] ?? vehicle.strColor,
          "Keys": vehicle.curKeys ?? "",
          "MSRP": vehicle.curVehMSRP ?? "",
          "Price": vehicle.curVehSalePrice ?? "",
          "Cost": vehicle.curVehCOS ?? "",
          "Warranty Sale": vehicle.curWarrSale ?? "",
          "Warranty Cost": vehicle.curWarrCost ?? "",
          "Warranty Type": vehicle.strWarrType ?? "",
          "Tag & Title Fees": vehicle.curTTFees ?? "",
          "VQ2 - Hold Back": vehicle.curVQ2 ?? "",
          "Vehicle Incentive": vehicle.curVehIncent ?? "",
          "PO Line Number": vehicle.strPONbr ?? "",
          "PO Number": getSalesOrdPONbr(vehicle.strSalesOrdNbr) ?? "",
          "DOAS Amount": vehicle.curDoas ?? "",
          "Accessories": vehicle.currAccess ?? "",
          "Upfit Cost": vehicle.curUpfitCost ?? "",
          "Transport Cost": vehicle.curTransCost ?? "",
          "Taxes": vehicle.curTaxes ?? "",
          "Rebate": vehicle.curRebate ?? "",
          "Manufacture ETA Date": formatDateUTC(vehicle.dtManETA) ?? "",
          "Production Date": formatDateUTC(vehicle.dtProdDate) ?? "",
          "Manufacture Status": vehicle.strManStat ?? "",
          "Received Date": formatDateUTC(vehicle.dtRecvDate) ?? "",
          "PDI Date": formatDateUTC(vehicle.dtPDI) ?? "",
          "Sales Date": formatDateUTC(vehicle.dtSaleDate) ?? "",
          "Upfitter Number": vehicle.strUpfitterNbr ?? "",
          "Upfitter Name": upfitter.strUpfitterName ?? "",
          "Sent To Upfitter Date": formatDateUTC(vehicle.dtSentToUpfitter) ?? "",
          "Upfitter ETA Date": formatDateUTC(vehicle.dtUpfitterETA) ?? "",
          "Upfitter Complete Date": formatDateUTC(vehicle.dtUpfitterComp) ?? "",
          "Upfitter Status": getUpfitterStatus(vehicle.strUpfitterStatus) ?? "",
          "Manual Status": vehicle.manualStatus ?? "",
          "Manual ETA Date": formatDateUTC(vehicle.manualETA) ?? "",
          "Billed CDK Indicator": vehicle.bBillCDK ?? false,
          "Recall Indicator": vehicle.bRecall ?? false,
          "Last Updated": formatDateUTC(vehicle.lastUpdated) ?? "",
          "Displayed Status": getDisplayedStatus(vehicle) ?? "",
          "Paid Date": formatDateUTC(vehicle.dtPaidDate) ?? "",
        };
      })
    );


    // Create a worksheet
    const worksheet = utils.json_to_sheet(dataToExport);

    // Create a workbook and append the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Vehicles");

    // Export the workbook
    const currentDate = new Date().toISOString().slice(0, 10);
    let exportName = "";
    if (section === 'salesorders') {
      exportName = "Bill_Out_"
    } 
    writeFile(workbook, `Selected_Vehicles_${exportName}${currentDate}.xlsx`);
  };

  
  const handleSortClick = (column) => {
    let direction = 'asc';

    // Toggle direction if the same column is clicked
    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    } else if (sortColumn === column && sortDirection === 'desc') {
      direction = 'asc';
    }

    setSortColumn(column);
    setSortDirection(direction);

    const sortedVehicles = [...filteredVehicles].sort((a, b) => {
      let fieldA, fieldB;

      if (column === 'strSalesOrdNbr') {
        // Custom sorting for strSalesOrdNbr
        const [dateA, seqA] = a.strSalesOrdNbr.split('-').map((part, index) => index === 1 ? parseInt(part, 10) : part);
        const [dateB, seqB] = b.strSalesOrdNbr.split('-').map((part, index) => index === 1 ? parseInt(part, 10) : part);

        if (dateA !== dateB) {
          return direction === 'asc' ? dateA.localeCompare(dateB) : dateB.localeCompare(dateA);
        }
        return direction === 'asc' ? seqA - seqB : seqB - seqA;
      }

      if (column === 'eta') {
        // Sort by ETA date
        fieldA = getETADateSort(a) || new Date(0);
        fieldB = getETADateSort(b) || new Date(0);
      } else if (column === 'customerName') {
        // Sort by customer name
        fieldA = getCustomerName(a.strSalesOrdNbr).toLowerCase();
        fieldB = getCustomerName(b.strSalesOrdNbr).toLowerCase();
      } else if (column === 'status') {
        // Sort by displayed status
        fieldA = getDisplayedStatus(a).toLowerCase();
        fieldB = getDisplayedStatus(b).toLowerCase();
      } else if (column === 'model') {
        // Sort by model name
        fieldA = (
          modelCodes[`${a.strFactory || ''} - ${a.strModel || ''}`] ||
          modelCodes[`${a.strFactory || ''} - ${a.strModel?.substring(0, 3) || ''}`] ||
          a.strModel || ''
        ).toLowerCase();
        fieldB = (
          modelCodes[`${b.strFactory || ''} - ${b.strModel || ''}`] ||
          modelCodes[`${b.strFactory || ''} - ${b.strModel?.substring(0, 3) || ''}`] ||
          b.strModel || ''
        ).toLowerCase();
      } else if (column === 'strStockNbr') {
        // Sort by stock number (last 8 digits of VIN if stock number is unavailable)
        fieldA = a['strVin'] ? a['strVin'].slice(-8).toLowerCase() : '';
        fieldB = b['strVin'] ? b['strVin'].slice(-8).toLowerCase() : '';
      } else {
        // Default sorting
        fieldA = a[column]?.toString().toLowerCase() || '';
        fieldB = b[column]?.toString().toLowerCase() || '';
      }

      if (direction === 'asc') {
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      } else {
        return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
      }
    });

    setFilteredVehicles(sortedVehicles);
  };

  const getDisplayedStatus = (vehicle) => {
    // 5. Delivered to Customer (Final State)
    if (vehicle.manualStatus === 'Scheduled') {
      return 'Scheduled';
    }
    if (vehicle.manualStatus === 'Produced') {
      return 'Produced';
    }
    if (vehicle.manualStatus === 'In Transit') {
      return 'In Transit';
    }
    if (vehicle.manualStatus === 'On the Ground') {
      return 'On the Ground';
    }
    if (vehicle.manualStatus === 'Customer Signed') {
      return 'Customer Signed';
    }
    if (vehicle.manualStatus === 'At Upfitter') {
      if (vehicle.strUpfitterStatus && vehicle.strUpfitterStatus !== 'Complete') {
        return getUpfitterStatus(vehicle.strUpfitterStatus);
      }
      return 'At Upfitter';
    }
    if (vehicle.manualStatus === 'Delivered') {
      return 'Delivered';
    }
    if (vehicle.manualStatus === 'Complete') {
      return 'Complete';
    }


    // 4. Process of Getting Vehicle Ready for Customer Delivery
    if (vehicle.dtCustSignedDate) {
      return 'Customer Signed and Ownership Changed';
    }

    if (vehicle.strUpfitterStatus && vehicle.strUpfitterStatus !== 'Complete') {
      return getUpfitterStatus(vehicle.strUpfitterStatus);
    }

    // 3. Ready for Customer Signature
    if (vehicle.dtRecvDate) {
      return 'On the Ground';
    }

    if (vehicle.strUpfitterStatus && vehicle.strUpfitterStatus === 'Complete') {
      return 'Delivered to Customer';
    }

    // 2. Vehicle In Process
    if (vehicle.strManStat || vehicle.dtProdDate || vehicle.dtManETA) {
      // a. In Transit to Destination
      if (vehicle.dtManETA) {
        return 'In Transit';
      }

      // b. Produced (GM status >= 3800 or Ford status "Produced")
      if (vehicle.strManStat && (parseInt(vehicle.strManStat) >= 3800 || vehicle.strManStat === 'Produced')) {
        return 'Produced';
      }

      // c. Scheduled (TPW or Build Date is set)
      if (vehicle.dtProdDate) {
        return 'Scheduled';
      }
    }

    // 1. Vehicle Ordered (Default Status)
    if (!vehicle.strManStat && !vehicle.dtProdDate && !vehicle.dtManETA) {
      return 'Vehicle Ordered';
    }

    // Default to 'Vehicle Ordered' if no other statuses are applicable
    return 'Vehicle Ordered';
  };


  const getUpfitterStatus = (strUpfitterStatus) => {
    try {
      const upfitterStatusCode = upfitterStatusCodes.find(code => code.strManStat === strUpfitterStatus.toString());
      return upfitterStatusCode ? upfitterStatusCode.strFactStatus : null;
    } catch (error) {
      return null;
    }
  };

  /*
  const handleCreateTeamsFolders = async () => {
    setShowTeamsModal(true);
    setTeamsLoading(true);
    setTeamsProgress(0);

    try {
      await createTeamsFoldersForVehicles(selectedVehicles, getDocument, queryData, modelCodes);
      setTeamsProgress(100);  // Assuming successful completion
    } catch (error) {
      console.error('Error creating Teams folders:', error);
    } finally {
      setTeamsLoading(false);
      setShowTeamsModal(false);
    }
  };
  */

  useEffect(() => {
    const fetchColorCodes = async () => {
      const colorCodesData = await getData('colorCodes');
      const colorCodesMap = colorCodesData.reduce((map, obj) => {
        map[obj.strFactory + " - " + obj.strColor] = obj.strColorName;
        return map;
      }, {});
      setColorCodes(colorCodesMap);
    };

    fetchColorCodes();
  }, [getData]);

  useEffect(() => {
    const fetchModelCodes = async () => {
      const modelCodesData = await getData('modelCodes');
      const modelCodesMap = modelCodesData.reduce((map, obj) => {
        map[obj.strFactory + " - " + obj.strModel] = obj.strModelName;
        return map;
      }, {});
      setModelCodes(modelCodesMap);
    };

    fetchModelCodes();
  }, [getData]);

  useEffect(() => {
    const filterVehicles = () => {
      const filtered = vehicles.filter(vehicle => {

        // Get the ETA date (convert Firestore Timestamp to JavaScript Date)
        const etaDate = getETADate(vehicle);
        
        // Check if the vehicle's ETA falls within the selected range
        const isWithinDateRange =
          (!etaStartDate || (etaDate && etaDate >= etaStartDate)) &&
          (!etaEndDate || (etaDate && etaDate <= etaEndDate));

        // Additional filtering logic (search query, status, etc.)
        const lowercasedQuery = searchQuery.toLowerCase();
        const matchesQuery = (
          vehicle.strOrdNbr?.toLowerCase().includes(lowercasedQuery) ||
          vehicle.nDAN?.toString().toLowerCase().includes(lowercasedQuery) ||
          vehicle.strVin?.toLowerCase().includes(lowercasedQuery) ||
          modelCodes[(vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel : "")]?.toLowerCase().includes(lowercasedQuery) ||
          modelCodes[(vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")]?.toLowerCase().includes(lowercasedQuery) ||
          modelCodes[
          (vehicle.strMake ? capitalizeFirstLetter(vehicle.strMake) : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
          ]?.toLowerCase().includes(lowercasedQuery) ||
          modelCodes[
          ("GM") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
          ]?.toLowerCase().includes(lowercasedQuery) ||
          (allVehicles && vehicle.strSalesOrdNbr?.toLowerCase().includes(lowercasedQuery)) ||
          vehicle.strMake?.toLowerCase().includes(lowercasedQuery) ||
          getCustomerName(vehicle.strSalesOrdNbr)?.toLowerCase().includes(lowercasedQuery)
        );

        let paidOnly = true;
        if (paidSelected) {
          paidOnly = (vehicle.dtPaidDate ? true : false)
        } 

        // Filter based on the selected statuses ("Active" shows all except "Complete")
        let isStatusIncluded
        if (selectedVehicleStatuses.includes('All')) {
          isStatusIncluded = true
        } else {
          isStatusIncluded = selectedVehicleStatuses.includes('Complete')
            ? vehicle.manualStatus === 'Complete'
            : vehicle.manualStatus !== 'Complete';
        }
        /*
        let isPaid
        if (paidSelected.includes('All')) {
          isStatusIncluded = true
        } else {
          isStatusIncluded = selectedVehicleStatuses.includes('Complete')
            ? vehicle.manualStatus === 'Complete'
            : vehicle.manualStatus !== 'Complete';
        }*/     

        // Combine all conditions
        return isWithinDateRange && matchesQuery && isStatusIncluded && paidOnly
      });

      setFilteredVehicles(filtered);
      setTotalRows(filtered.length);
      setCurrentPage(1); // Reset to the first page when filtering changes
    };

    filterVehicles();
  }, [vehicles, etaStartDate, etaEndDate, searchQuery, allVehicles, selectedVehicleStatuses, paidSelected]);


  const handleVehicleRowClick = (vehicle) => {
    if (selectedVehicle && selectedVehicle.strOrdNbr === vehicle.strOrdNbr) {
      setSelectedVehicle(null); // Deselect if the same vehicle is clicked again
    } else {
      setSelectedVehicle(vehicle);
    }
  };

  const handleVehicleAdded = async () => {
    const vehicleData = selectedOrder
      ? await queryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr)
      : await getData('vehicles');

    setVehicles(vehicleData);
    setShowBulkEditModal(false);
    setShowAddModal(false); // Close the modal after adding the vehicle
  };

  const formatCurrency = (value) => {
    if (value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    }
    return '';
  };


  const handleVehicleUpdated = async (updatedVehicle) => {
    if (!updatedVehicle || !updatedVehicle.id) {
      console.error('Updated vehicle or vehicle ID is missing');
      return;
    }
    await updateData('vehicles', updatedVehicle.id, updatedVehicle);
    const updatedVehicles = vehicles.map(v => (v.id === updatedVehicle.id ? updatedVehicle : v));
    setVehicles(updatedVehicles);
    setShowBulkEditModal(false); // Close the modal after updating the vehicle
  };

  const handleBulkEditClick = () => {
    if (selectedVehicles.length === 0) {
      return;
    }
    setVehicleToEdit(selectedVehicles);
    setShowBulkEditModal(true);
  };

  const handleCSVFile = async (file) => {
    setLoading(true);
    setProgress(0);

    Papa.parse(file, {
      header: true,
      complete: async function (results) {
        const totalRows = results.data.length;
        let processedRows = 0;

        for (const row of results.data) {
          const vin = row['VIN'];
          const vinStatus = row['VIN Status'];
          const lastMile = row['Last Milestone']
          let etaDate = null;

          // Check the VIN status and assign the appropriate date field
          if (vinStatus === 'Delivered') {
            etaDate = parseValidDate(row['Last Milestone Local Date']);
          } else {
            etaDate = parseValidDate(row['End Intelligent ETA Local Date']);
          }

          if (etaDate) {
            // Find the vehicle in Firestore by VIN
            const vehicleData = await queryData('vehicles', 'strVin', '==', vin);
            const dtManETAValue = etaDate === "TBD" ? null : Timestamp.fromDate(etaDate);
            if (vehicleData.length > 0) {
              const vehicle = vehicleData[0];
              if (lastMile) {
                // Update the vehicle's dtManETA field
                await updateData('vehicles', vehicle.id, {
                  dtManETA: dtManETAValue,
                  strVinStatus: vinStatus,
                  strManStat: lastMile
                });
              } else {
                // Update the vehicle's dtManETA field
                await updateData('vehicles', vehicle.id, {
                  dtManETA: dtManETAValue,
                  strVinStatus: vinStatus
                });
              }

              // Update the vehicle's dtManETA field
              await updateData('vehicles', vehicle.id, {
                dtManETA: dtManETAValue,
                strVinStatus: vinStatus
              });
            }
          } else {
            console.log(`Skipping vehicle with VIN ${vin}: Invalid or missing date`);
          }

          // Update progress
          processedRows++;
          setProgress(Math.round((processedRows / totalRows) * 100));
        }

        setLoading(false);
        setReload(reload + 1); // Trigger reload
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
        setLoading(false);
      }
    });
  };

  // Helper function to validate and parse dates in MM/DD/YYYY format
  const parseValidDate = (dateString) => {
    if (!dateString || typeof dateString !== 'string') return null;

    // Check for "TBD - On Hold" and return it directly if found
    if (dateString === "TBD - On Hold" || dateString === "TBD") {
      return "TBD";
    }

    // Use regular expression to match MM/DD/YYYY format
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return null; // Return null for invalid date formats
    }

    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date; // Return null if the date is invalid
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name.toLowerCase(); // Convert the file name to lowercase

    if (fileName.includes('vinview')) {
      await handleCSVFile(file); // Handle CSV file for VINView
    } else if (fileName.includes('ford') || fileName.includes('gm')) {
      const data = await file.arrayBuffer();
      const workbook = read(data, { type: 'array' });
      if (fileName.includes('ford')) {
        await handleFordFile(workbook);
      } else {
        await handleGMFile(workbook);
      }
    }
  };

  const handleVINViewFile = async (workbook) => {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = utils.sheet_to_json(worksheet);

    setLoading(true);
    setProgress(0);

    // For progress tracking
    const totalRows = json.length;
    let processedRows = 0;

    for (const row of json) {
      const vin = row['VIN'];
      const vinStatus = row['VIN Status'];
      let etaDate;

      // Check the VIN status and assign the appropriate date field
      if (vinStatus === 'Delivered') {
        etaDate = excelDateToJSDate(row['Last Milestone Local Date']);
      } else {
        etaDate = excelDateToJSDate(row['End Intelligent ETA Local Date']);
      }

      // Find the vehicle in Firestore by VIN
      const vehicleData = await queryData('vehicles', 'strVin', '==', vin);
      if (vehicleData.length > 0) {
        const vehicle = vehicleData[0];

        // Update the vehicle's dtManETA field
        await updateData('vehicles', vehicle.id, {
          dtManETA: Timestamp.fromDate(etaDate)
        });
      }

      // Update progress
      processedRows++;
      setProgress(Math.round((processedRows / totalRows) * 100));
    }

    setLoading(false);
    setReload(reload + 1); // Trigger reload
  };

  const getCircleColor = (percentage) => {
    if (percentage >= 75) {
      return "green"; // High percentage = green
    } else if (percentage >= 45) {
      return "yellow"; // Medium percentage = yellow
    } else if (percentage >= 10) {
      return "orange"; // Low percentage = orange
    } else {
      return "white"; // Very low percentage = red
    }
  };


  const handleGMFile = async (workbook) => {
    const sheetNames = workbook.SheetNames;
    let totalVehicles = 0;

    // Calculate total vehicles for progress tracking
    sheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet); // No longer skipping any rows
      totalVehicles += json.length;
    });

    setLoading(true);

    for (const sheetName of sheetNames) {
      let processedVehicles = 0;
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet); // No longer skipping any rows
      const mappedVehicles = json.map(vehicle => ({ ...mapGMFields(vehicle) }));

      processedVehicles += mappedVehicles.length;
      setProgress(Math.round((processedVehicles / totalVehicles) * 100));

      await updateVehicles(mappedVehicles, setProgress, async () => {
        const vehicleData = await getData('vehicles');
        setVehicles(vehicleData);
        setFilteredVehicles(vehicleData); // Update filtered vehicles as well
      });
    }

    setLoading(false);
  };



  const handleFordFile = async (workbook) => {
    const sheetNames = workbook.SheetNames;
    let totalVehicles = 0;

    // Calculate total vehicles for progress tracking
    sheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { range: 2 }); // Skip the first two rows
      totalVehicles += json.length;
    });

    setLoading(true);

    for (const sheetName of sheetNames) {
      let processedVehicles = 0;
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { range: 2 }); // Skip the first two rows
      const mappedVehicles = json.map(vehicle => ({ ...mapFordFields(vehicle) }));

      processedVehicles += mappedVehicles.length;
      setProgress(Math.round((processedVehicles / totalVehicles) * 100));

      await updateVehicles(mappedVehicles, setProgress, async () => {
        const vehicleData = await getData('vehicles');
        setVehicles(vehicleData);
        setFilteredVehicles(vehicleData); // Update filtered vehicles as well
      });
    }
    setLoading(false);
  };

  const updateVehicles = async (vehicles, setProgress, callback) => {
    const vehiclesData = await getData('vehicles'); // Fetch existing vehicle data from Firestore
    const activeSalesOrders = await getData('salesOrders');
    const activeOrders = activeSalesOrders.filter(order => order.strSalesOrdStatus === 'Active');

    for (let i = 0; i < vehicles.length; i++) {
      const vehicle = vehicles[i];
      if (vehicle['Status'] === 'Saved') {
        continue;
      }
      // Set current vehicle info to display
      setCurrentVehicleInfo({
        strOrdNbr: vehicle['Order'],
        strVin: vehicle['VIN'],
        strYR: vehicle['Year'],
        strMake: vehicle['Make'],
        strModel: vehicle['Model']
      });

      const result = await findVehicle(vehicle, activeOrders, vehiclesData);

      if (result.found) {
        // If vehicle is found, only update fields that are not blank
        setCurrentVehicleStatus('Updated');
        const updateDataFields = {};

        Object.keys(vehicle).forEach(field => {
          if (vehicle[field] !== '' && vehicle[field] !== null && vehicle[field] !== undefined) {
            updateDataFields[field] = vehicle[field];
          }
        });

        if (Object.keys(updateDataFields).length > 0) {
          await updateData('vehicles', result.vehicle.id, updateDataFields);
        }
      } else if (result.strSalesOrdNbr) {
        // If the vehicle is new, add it
        setCurrentVehicleStatus('New');
        await addData('vehicles', {
          ...vehicle,
          strSalesOrdNbr: result.strSalesOrdNbr,
          createdDate: Timestamp.now(), // Only set `createdDate` for new vehicles
        });
      }
      // Update progress
      setProgress(Math.round(((i + 1) / vehicles.length) * 100));
    }

    // Call the callback function to update the state
    if (callback) {
      callback();
    }
  };



  const normalizePrice = (price) => {
    if (!price) return 0;

    // Remove any dollar signs ($) and commas (,)
    const normalizedPrice = price.replace(/[$,]/g, '');

    // Convert the remaining string to a floating-point number
    return parseFloat(normalizedPrice) || 0;
  };


  const findVehicle = async (importedVehicle, activeOrders, vehiclesData) => {
    let existingVehicle = null;

    // Check if the vehicle exists by VIN (ensure VIN exists and isn't empty)
    if (importedVehicle.strVin && importedVehicle.strVin.trim() !== '') {
      existingVehicle = vehiclesData.find(vehicle => vehicle.strVin === importedVehicle.strVin);
      if (existingVehicle) {
        return { found: true, vehicle: existingVehicle };
      }
    }

    // If VIN is missing or not found, check if the vehicle exists by Order # (ensure Order # exists and isn't empty)
    if (importedVehicle.strOrdNbr && importedVehicle.strOrdNbr.trim() !== '') {
      existingVehicle = vehiclesData.find(vehicle => vehicle.strOrdNbr === importedVehicle.strOrdNbr);
      if (existingVehicle) {
        return { found: true, vehicle: existingVehicle };
      }
    }

    // If no vehicle is found by VIN or Order #, look for a match in activeOrders based on DAN or Ford Order Number
    for (const order of activeOrders) {
      // Check the DAN (Dealer Account Number) in activeOrders
      if (order.arrDans && order.arrDans.includes(importedVehicle.nDAN?.toString())) {
        return { found: false, strSalesOrdNbr: order.strSalesOrdNbr };
      }

      // Check for matching Order Numbers in activeOrders (this will use strOrdNbr from the vehicle object)
      if (order.arrFordOrders && order.arrFordOrders.includes(importedVehicle.strOrdNbr?.toString())) {
        return { found: false, strSalesOrdNbr: order.strSalesOrdNbr };
      }
    }

    // If no match is found, return as not found with no Sales Order Number
    return { found: false, strSalesOrdNbr: null };
  };


  const excelDateToJSDate = (serial) => {
    if (isNaN(serial)) {
      return null;
    }
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    return new Date(utc_value * 1000);
  };

  const mapGMFields = (vehicle) => {
    const currentEventDate = excelDateToJSDate(vehicle["Current Event Date"]);
    const prodDate = excelDateToJSDate(vehicle["TPW"]);

    return {
      strOrdNbr: String(vehicle["Order #"]) || '',
      strGMConfigID: vehicle["GM Config ID"] || '',
      strOrderType: vehicle["Order Type"] || '',
      curVehMSRP: normalizePrice(vehicle["MSRP w/DFC†"]) || 0,
      dtProdDate: prodDate || null,
      strFactory: "GM",
      strMake: vehicle["Division"] || '',
      strModel: vehicle["Model"] || vehicle["Model Code"] || '',
      strColor: vehicle["Color"] || '',
      strManStat: vehicle["Current Event"] || '',
      strCustName: vehicle["Business Customer Name"] || '',
      strYR: vehicle["Model Year"] || '',
      strDelivStat: vehicle["Deliver Vehicle"] || '',
      curVehIncent: vehicle["Lookup Incentives by VIN"] || 0,
      lstrCustNotes: vehicle["Remarks"] || '',
      dtCurrentEvent: currentEventDate || null,
      nDAN: vehicle["DAN"] || '',
      strVin: vehicle["VIN"] || '',
      //createdDate: Timestamp.now(), Add this line for the current Firestore timestamp
    };
  };

  const mapFordFields = (vehicle) => {
    const buildDate = excelDateToJSDate(vehicle["BuildDate"]);
    const receiptDate = excelDateToJSDate(vehicle["Receipt Date"]);
    const prodDate = excelDateToJSDate(vehicle["BuildDate"] || vehicle["BuildWeek"]);

    return {
      strOrdNbr: String(vehicle["Order"]) || '',
      strGMConfigID: vehicle["Reservation ID"] || '', // Assuming this as a placeholder for Ford Config ID
      strOrderType: vehicle["Type"] || '',
      strFactory: 'Ford', // Make is Ford for all Ford reports
      strMake: 'Ford',
      strModel: vehicle["Body"] || '',
      strColor: vehicle["Paint"] || '',
      strManStat: vehicle["Status"] || '',
      strYR: vehicle["Year"] || '',
      strDelivStat: '', // Delivery Status not provided in Ford headers
      curVehIncent: 0, // Incentives not provided in Ford headers
      lstrCustNotes: vehicle["SVS Reason"] || '',
      dtCurrentEvent: buildDate || null, // Assuming build date as current event date
      nDAN: '', // DAN not provided in Ford headers
      strVin: vehicle["VIN"] || '',
      dtProdDate: prodDate || null,
      //createdDate: Timestamp.now(),
    };
  };

  const handleDeleteClick = (vehicle) => {
    setShowDeleteModal(true);
  };

  const handleDetailsClick = (vehicle) => {
    setVehicleToShow(vehicle);
    setShowDetailModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const confirmDelete = async () => {
    try {
      // Loop through each selected vehicle and delete them one by one
      for (const vehicle of selectedVehicles) {
        await deleteData('vehicles', vehicle.id);
      }

      // Filter out the deleted vehicles from the state
      const updatedVehicles = vehicles.filter(vehicle => !selectedVehicles.includes(vehicle));
      setVehicles(updatedVehicles);
      setFilteredVehicles(updatedVehicles); // Ensure UI sync
      setTotalRows(updatedVehicles.length);
      setSelectedVehicles([]); // Clear the selection

      setShowDeleteModal(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting vehicles:", error);
    }
  };

  const toggleVehicleSelection = (vehicle) => {
    if (selectedVehicles.includes(vehicle)) {
      setSelectedVehicles(selectedVehicles.filter(v => v !== vehicle));
    } else {
      setSelectedVehicles([...selectedVehicles, vehicle]);
    }
  };
  

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedVehicles([]);
    } else {
      setSelectedVehicles(currentRows);
    }
    setSelectAll(!selectAll);
  };

  const togglePaidSelected = () => {
    if (paidSelected) {
      setPaidSelected(false);
    } else {
      setPaidSelected(true);
    }
  };

  const handleBulkEditHide = () => {
    setSelectedVehicles([]);
    setShowBulkEditModal(false)
  }

  const handleBulkEditSubmit = async (updatedFields) => {
    const promises = selectedVehicles.map(vehicle => {
      // Create an updated vehicle object with only changed fields
      const updatedVehicle = {};

      // Loop through the updatedFields and only add the fields that have changed or are not empty
      Object.keys(updatedFields).forEach(field => {
        if (updatedFields[field] !== '' && updatedFields[field] !== vehicle[field]) {
          updatedVehicle[field] = updatedFields[field]; // Add only changed fields
        }
      });

      // Update only if there are fields to update
      if (Object.keys(updatedVehicle).length > 0) {
        return updateData('vehicles', vehicle.id, updatedVehicle);
      }

      return Promise.resolve(); // No changes, so return a resolved promise
    });

    try {
      await Promise.all(promises);
      const vehicleData = selectedOrder
        ? await queryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr)
        : await getData('vehicles');
      setVehicles(vehicleData);
      setShowBulkEditModal(false);
    } catch (error) {
      console.error('Error updating vehicles:', error);
    }
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredVehicles.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup>
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search vehicles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      {/* Wrap the table in a responsive container */}
      <div className="table-responsive" style={{ minHeight: '300px' }}>
        <Table hover style={{ whiteSpace: 'nowrap' }}>
          <thead>
            <tr>
              {addButtonActive && (
                <th>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                      className="me-2"
                    />
                    {!allVehicles && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Add Vehicle</Tooltip>}
                      >
                        <Button
                          onClick={() => handleAddClick()}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          className="icon-hover"
                          style={{ padding: '2px 4px' }}
                          variant="link"
                        >
                          {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                        </Button>
                      </OverlayTrigger>
                    )}

                    {/* Import Vehicles Button */}
                    {allVehicles && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Import Vehicles</Tooltip>}
                        >
                          <Button
                            onClick={() => document.getElementById('file-input').click()}
                            variant="white"
                            style={{ padding: '0px 0px' }}
                          >
                            <ArrowBarUp size={20} color="blue" />
                          </Button>
                        </OverlayTrigger>
                        <input
                          type="file"
                          id="file-input"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          accept=".xlsx, .xls, csv"
                        />
                      </>
                    )}

                    {/* Bulk Edit Button */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Edit Vehicle(s)</Tooltip>}
                    >
                      <Button
                        onClick={() => handleBulkEditClick()}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        <Wrench size={18} color="black" />
                      </Button>
                    </OverlayTrigger>

                    {/* Bulk Delete Button */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Delete Vehicle(s)</Tooltip>}
                    >
                      <Button
                        onClick={() => handleDeleteClick(selectedVehicles)}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        <Trash size={18} color="red" />
                      </Button>
                    </OverlayTrigger>
                    {/* Bulk Export Button */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Export Selected Vehicles</Tooltip>}
                    >
                      <Button
                        onClick={handleExportToXLSX}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        <FiletypeXlsx size={18} color="green" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Paid Only</Tooltip>}
                    >
                      <Button
                        onClick={() => togglePaidSelected()}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        {paidSelected ? <CurrencyDollar color="green" /> : <CurrencyDollar />}
                      </Button>
                    </OverlayTrigger>
                  </div>
                </th>
              )}
              {allVehicles && (
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>SO #</span>
                    <Button onClick={() => handleSortClick('strSalesOrdNbr')} variant='white'><ArrowDownUp /></Button>
                  </div>
                </th>
              )}
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Order #</span>
                  <Button onClick={() => handleSortClick('strOrdNbr')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>DAN</span>
                  <Button onClick={() => handleSortClick('nDAN')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              {/*
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>VIN</span>
                  <Button onClick={() => handleSortClick('strVin')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              */}
              {allVehicles && (
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Customer Name</span>
                    <Button onClick={() => handleSortClick('customerName')} variant='white'>
                      <ArrowDownUp />
                    </Button>
                  </div>
                </th>
              )}
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Stock #</span>
                  <Button onClick={() => handleSortClick('strStockNbr')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Make</span>
                  <Button onClick={() => handleSortClick('strMake')} variant="white">
                    <ArrowDownUp />
                  </Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Model</span>
                  <Button onClick={() => handleSortClick('model')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>ETA</span>
                  <Button onClick={() => handleSortClick('eta')} variant="white">
                    <ArrowDownUp />
                  </Button>
                  <Dropdown show={showDateFilter} onToggle={() => setShowDateFilter(!showDateFilter)}>
                    <Dropdown.Toggle variant="white" id="eta-date-filter">
                      <Funnel />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" style={{ padding: '10px', minWidth: '200px' }}>
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={etaStartDate ? etaStartDate.toISOString().slice(0, 10) : ''} // Set value
                          onChange={(e) => setEtaStartDate(e.target.value ? new Date(e.target.value) : null)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={etaEndDate ? etaEndDate.toISOString().slice(0, 10) : ''} // Set value
                          onChange={(e) => setEtaEndDate(e.target.value ? new Date(e.target.value) : null)}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="mt-2"
                        onClick={() => setShowDateFilter(false)} // Close the dropdown
                      >
                        Apply
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="mt-2 ms-2"
                        onClick={() => {
                          setEtaStartDate(null);
                          setEtaEndDate(null);
                        }} // Reset dates
                      >
                        Clear
                      </Button>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="me-1">Status</span>
                  <Button
                    onClick={() => handleSortClick('status')}
                    variant='white'
                    className="me-1" // Reduce the space between Button and Dropdown
                    style={{ padding: '1px 2px' }} // Optional: Adjust button padding
                  >
                    <ArrowDownUp />
                  </Button>
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="white"
                    title={selectedVehicleStatuses}
                    id="input-group-dropdown-vehicle-status"
                    drop="down"
                    menuAlign="right"
                    container="body"
                  >
                    {['Active', 'Complete', 'All'].map(status => (
                      <Dropdown.Item
                        key={status}
                        active={selectedVehicleStatuses.includes(status)}
                        onClick={() => handleVehicleStatusSelect(status)}
                      >
                        {status}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>

              </th>
              <th>
                <div style={{ position: 'relative', display: 'inline-block', width: '30px', height: '30px' }}>
                  {/* Circle Icon */}
                  <CircleFill
                    size={30}
                    color={getCircleColor(calculateGreenPercentage())}  // Use the status color
                  />
                  {/* Text inside the circle */}
                  <span style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                    {calculateGreenPercentage()}{"%"} {/* Replace `someText` with the desired text */}
                  </span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {currentRows.map(vehicle => (
              <tr key={vehicle.id} onClick={() => handleVehicleRowClick(vehicle)} className="align-middle">
                {addButtonActive && (
                  <td>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        className="me-2"
                        checked={selectedVehicles.includes(vehicle)}
                        onChange={() => toggleVehicleSelection(vehicle)}
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Details</Tooltip>}
                      >
                        <Button
                          onClick={() => handleDetailsClick(vehicle)}
                          variant="white"
                          style={{ padding: '0px 0px' }}
                        >
                          <InfoCircle color="blue" />
                        </Button>
                      </OverlayTrigger>
                      {getDOASReq(vehicle.strSalesOrdNbr) &&
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">DOAS Required</Tooltip>}>
                          <span style={{ marginLeft: '10px' }}><Buildings /></span>
                        </OverlayTrigger>
                      }
                      {vehicle.dtPaidDate &&
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Paid</Tooltip>}>
                          <span style={{ marginLeft: '10px' }}><CurrencyDollar /></span>
                        </OverlayTrigger>
                      }
                    </div>
                  </td>
                )}
                {allVehicles &&
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Navigate to Sales Order</Tooltip>}>
                      <td onClick={() => handleSalesOrderClick(vehicle.strSalesOrdNbr)}>{vehicle.strSalesOrdNbr}</td>
                    </OverlayTrigger>
                  </td>
                  }
                <td>{vehicle.strOrdNbr}</td>
                <td>{vehicle.nDAN}</td>
                {allVehicles && 
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{getCustomerName(vehicle.strSalesOrdNbr)}</Tooltip>}>
                      <span>{getCustomerName(vehicle.strSalesOrdNbr)}</span>
                    </OverlayTrigger>
                  </td>
                }
                {/*<td>{vehicle.strVin}</td>*/}
                <td>
                  {vehicle.strStockNbr
                    ? vehicle.strStockNbr
                    : vehicle.strVin && vehicle.strVin.length >= 8
                      ? vehicle.strVin.slice(-8)
                      : vehicle.strVin || ""
                  }
                </td>
                <td>{vehicle.strYR} {vehicle.strMake}</td>
                <td>
                  {modelCodes[
                    (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel : "")
                  ] ||
                    modelCodes[
                    (vehicle.strFactory ? vehicle.strFactory : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
                    ] ||
                    modelCodes[
                    (vehicle.strMake ? capitalizeFirstLetter(vehicle.strMake) : "") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
                    ] ||
                    modelCodes[
                    ("GM") + " - " + (vehicle.strModel ? vehicle.strModel.substring(0, 3) : "")
                    ] ||
                    (vehicle.strModel || "")
                  }
                </td>
                <td>{getETAStr(vehicle)}</td>
                <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{getDisplayedStatus(vehicle)}</Tooltip>}>
                    <span>{getDisplayedStatus(vehicle)}</span>
                  </OverlayTrigger>                
                </td>
                <td>
                  <CircleFill
                    size={30}
                    color={getStatusColor(vehicle)}  // Calculate the color dynamically based on progress
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className={`d-flex justify-content-between align-items-center my-1 ${selectedOrder ? 'mb-1' : (rowsPerPage === 15 ? 'mb-3' : 'mb-5')}`} style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={999999}>All</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <Spinner animation="border" variant="primary" />
          <ProgressBar now={progress} label={`${progress}%`} style={{ width: '50%', marginTop: '20px' }} />
          {/*currentVehicleInfo && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <strong>{currentVehicleStatus}: </strong>
              {currentVehicleInfo.strOrdNbr} / {currentVehicleInfo.strVin} / {currentVehicleInfo.strYR} / {currentVehicleInfo.strMake} / {currentVehicleInfo.strModel}
            </div>
          )*/}
        </div>
      )}


      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedVehicles.length > 1
            ? `Are you sure you want to delete these ${selectedVehicles.length} vehicles?`
            : "Are you sure you want to delete this vehicle?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={confirmDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* Add Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VehicleForm
            salesOrderNumber={(allVehicles ? null : selectedOrder?.strSalesOrdNbr)}
            onVehicleAdded={handleVehicleAdded}
            modalCodes={modelCodes}
            addVehicle={true}
          />
        </Modal.Body>
      </Modal>

      {/* Detail Modal */}
      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vehicleToShow && (
            <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
              <Row>
                <Col xs={3}><strong>Last Updated</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.lastUpdated) || ''}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Vehicle Ordered</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.createdDate) || ''}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Sales Order #</strong></Col>
                <Col xs={3}>{vehicleToShow.strSalesOrdNbr || ''}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Order #</strong></Col>
                <Col xs={3}>{vehicleToShow.strOrdNbr || ''}</Col>
                <Col xs={3}><strong>Stock #</strong></Col>
                <Col xs={3}>{vehicleToShow.strStockNbr || vehicleToShow.strVin?.slice(-8) || ""}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>DAN</strong></Col>
                <Col xs={3}>{vehicleToShow.nDAN || ''}</Col>
                <Col xs={3}><strong>PO #</strong></Col>
                <Col xs={3}>{getSalesOrdPONbr(vehicleToShow.strSalesOrdNbr)}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>VIN</strong></Col>
                <Col xs={3}>{vehicleToShow.strVin || ''}</Col>
                <Col xs={3}><strong>PO Line #</strong></Col>
                <Col xs={3}>{vehicleToShow.strPONbr || ''}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Year</strong></Col>
                <Col xs={3}>{vehicleToShow.strYR || ''}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Make</strong></Col>
                <Col xs={3}>{vehicleToShow.strMake || ''}</Col>
                <Col xs={3}><strong>Color</strong></Col>
                <Col xs={3}>{vehicleToShow.strColor || ''}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Model Code</strong></Col>
                <Col xs={3}>{vehicleToShow.strModel || ''}</Col>
                <Col xs={3}><strong>Model Name</strong></Col>
                <Col xs={3}>{modelCodes[
              (vehicleToShow.strFactory ? vehicleToShow.strFactory : "") + " - " + (vehicleToShow.strModel ? vehicleToShow.strModel : "")
            ] ||
              modelCodes[
              (vehicleToShow.strFactory ? vehicleToShow.strFactory : "") + " - " + (vehicleToShow.strModel ? vehicleToShow.strModel.substring(0, 3) : "")
              ] ||
              (vehicleToShow.strModel || "")}</Col>
              </Row>
              {/* Row 1 */}
              <Row>
                <Col xs={3}><strong>Factory Status</strong></Col>
                <Col xs={3}>{vehicleToShow.strManStat || ''}</Col>
                <Col xs={3}><strong>Factory ETA Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtManETA) || ''}</Col>
              </Row>
              {/* Row 2 */}
              <Row>
                <Col xs={3}><strong>Manual Status</strong></Col>
                <Col xs={3}>{vehicleToShow.strManualStat || ''}</Col>
                <Col xs={3}><strong>Manual ETA Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtManualETA) || ''}</Col>
              </Row>
              {/* Row 3 */}
              <Row>
                <Col xs={3}><strong>Drop Ship Ind</strong></Col>
                <Col xs={3}>{vehicleToShow.bDropInd ? 'Yes' : 'No'}</Col>
                <Col xs={3}><strong>Dropship Location</strong></Col>
                <Col xs={3}>{vehicleToShow.strDropLoc || ''}</Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 4 */}
              <Row>
                <Col xs={3}><strong>Keys</strong></Col>
                <Col xs={3}>{vehicleToShow.curKeys || ''}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 5 */}
              <Row>
                <Col xs={3}><strong>Vehicle Price</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curVehSalePrice)}</Col>
                <Col xs={3}><strong>Vehicle MSRP</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curVehMSRP)}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Vehicle Cost</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curVehCOS)}</Col>
                <Col xs={3}><strong>VQ2\Hold Back:</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curVQ2)}</Col>
              </Row>
              {/* Row 6 */}
              <Row>
                <Col xs={3}><strong>Upfit Cost</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curUpfitCost)}</Col>
                <Col xs={3}><strong>Accessories</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.currAccess)}</Col>
              </Row>
              {/* Row 7 */}
              <Row>
                <Col xs={3}><strong>Incentives</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curVehIncent)}</Col>
                <Col xs={3}><strong>Rebate</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curRebate)}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>DOAS</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curDoas)}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              {/* Row 8 */}
              <Row>
                <Col xs={3}><strong>Tag & Title</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curTTFees)}</Col>
                <Col xs={3}><strong>Taxes</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curTaxes)}</Col>
              </Row>
              {/* Row 9 */}
              <Row>
                <Col xs={3}><strong>Transportation</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curTransCost)}</Col>
                <Col xs={3}><strong>Warranty Type</strong></Col>
                <Col xs={3}>{vehicleToShow.strWarrType || ''}</Col>
              </Row>
              {/* Row 10 */}
              <Row>
                <Col xs={3}><strong>Warranty Sale</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curWarrSale)}</Col>
                <Col xs={3}><strong>Warranty Cost</strong></Col>
                <Col xs={3}>{formatCurrency(vehicleToShow.curWarrCost)}</Col>
              </Row>
              <Row>
              </Row>
              {/* Row 11 */}
              <Row>
                <Col xs={3}><strong>Production Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtProdDate) || ''}</Col>
                <Col xs={3}><strong>PDI Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtPDI) || ''}</Col>
              </Row>
              {/* Row 12 */}
              <Row>
                <Col xs={3}><strong>Received Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtRecvDate) || ''}</Col>
                <Col xs={3}><strong>Delivered Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtDelivDate) || ''}</Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 13 */}
              <Row>
                <Col xs={3}><strong>Sale Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtSaleDate) || ''}</Col>
                <Col xs={3}><strong>Paid Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtPaidDate) || ''}</Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 14 */}
              <Row>
                <Col xs={3}><strong>Upfitter Name</strong></Col>
                <Col xs={3}>{getUpfitter(vehicleToShow.strUpfitterNbr)?.strUpfitterName || ''}</Col>
                <Col xs={3}><strong>Upfitter Status</strong></Col>
                <Col xs={3}>{getUpfitterStatus(vehicleToShow.strUpfitterStatus) || ''}</Col>
              </Row>
              {/* Row 15 */}
              <Row>
                <Col xs={3}><strong>Sent To Upfitter</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtSentToUpfitter) || ''}</Col>
                <Col xs={3}><strong>Upfitter ETA Date</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtUpfitterETA) || ''}</Col>
              </Row>
              <Row>
                <Col xs={3}><strong>Upfitter Complete Date:</strong></Col>
                <Col xs={3}>{formatDateUTC(vehicleToShow.dtUpfitterComp)}</Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 16 */}
              <Row>
                <Col xs={3}><strong>Billed CDK</strong></Col>
                <Col xs={3}>{vehicleToShow.bBillCDK ? 'Yes' : 'No'}</Col>
                <Col xs={3}><strong>Recall CDK</strong></Col>
                <Col xs={3}>{vehicleToShow.bRecall ? 'Yes' : 'No'}</Col>
              </Row>
              <Row>
                <Col xs={9}> </Col>
              </Row>
              {/* Row 17 */}
              <Row>
                <Col xs={3}><strong>Bill Notes</strong></Col>
                <Col xs={9}>{vehicleToShow.strBillNotes || ''}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>


      {/* Bulk Edit Modal */}
      <Modal show={showBulkEditModal} onHide={handleBulkEditHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Vehicle(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VehicleForm
            salesOrderNumber={allVehicles ? null : selectedOrder?.strSalesOrdNbr}
            vehicle={vehicleToEdit}
            onVehicleAdded={handleVehicleAdded}
            onVehicleUpdated={(updatedVehicle) => {
              handleVehicleUpdated(updatedVehicle);
              setShowBulkEditModal(false); // Close the modal after updating
              setSelectedVehicles([]);
              setReload(reload + 1);
            }}
            onBulkEditSubmit={(updatedFields) => {
              handleBulkEditSubmit(updatedFields);
              setShowBulkEditModal(false); // Close the modal after bulk editing
              setSelectedVehicles([]);
              setReload(reload + 1);
            }}
            modelCodes={modelCodes}
          />
        </Modal.Body>
      </Modal>

      {/* Teams Progress Modal */}
      <Modal show={showTeamsModal} onHide={() => setShowTeamsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Teams Folders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {teamsLoading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <ProgressBar now={teamsProgress} label={`${teamsProgress}%`} className="mt-3" />
            </div>
          ) : (
            <div>Folders have been successfully created!</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );


}

export default VehicleList;
